import { ArrowRight, Campaign, Check, Circle, DirectionsWalk, PanTool, VisibilityOff } from '@mui/icons-material';
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { SocketContext } from '../../../context/socket';
import AppLayout from '../../../layout/components/AppLayout'
import UpdateStatusComponent from '../../Appointments/components/UpdateStatusComponent';
import checkinServices from '../checkinServices';
import TransferToken from '../components/TransferToken';
import withAlert from '../../../hoc/withAlert';
import TokenVisitorDetails from '../components/TokenVisitorDetails';


const LabelValue =({label,value})=>{
    return (
        <div className="row">
            <div className="col-6">
               <strong> {label} : </strong>
            </div>
            <div className="col-6">
                {value}
            </div>
        </div>
    )
}

const CheckinQueue = ({AppAlert}) => {
    
    const businessId = 1;
    const [pendingList,setPendingList] = useState(null);
    const [visitor,setVisitor] = useState(null);
    const [loading,setLoadingStatus] = useState(false);
    const socket = useContext(SocketContext);

    

    const [notificationList,setNotificationList] = useState(null);
    const getUserNotificationList = () =>{
        checkinServices.getUserNotificationList().then(res=>{
            setNotificationList(res.data)
        })
    }
    useEffect(()=>{
        getUserNotificationList();
    },[])


    const [holdTokenExist,setHoldTokenExist]  =   useState(0)
    useEffect(()=>{
        if(pendingList){
            let found = pendingList.find(token=>token.status == 7)
            if(found === undefined){
                setHoldTokenExist(false);
            } else {
                setHoldTokenExist(true);
            }
        }
    },[pendingList])

    const getPendingList = () => {
        checkinServices.getCheckinQueueList(businessId).then(res=>{
            setPendingList(null);
            if(res.data.length > 0 ){
                setPendingList(res.data)
            }
        })
    }

    const getPendingListFirstTime = () => {
        checkinServices.getCheckinQueueList(businessId).then(res=>{
            if(res.data.length > 0 ){
                setPendingList(res.data)
                console.log("first load here");
                let found = res.data.find(token=>token.status == 2)
                if(found !== undefined){
                    callVisitor();
                } else {
                    console.log("not found")
                }
            }
        })
    }

    useEffect(()=>{
        getPendingListFirstTime();
    },[])


    const callVisitor = () => {
        setLoadingStatus(true);
        checkinServices.callCheckinVisitor(businessId).then(res=>{
            setVisitor(res.data);
            socket.emit("send_announcement",{message:"Hello App1","token": res.data})
        }).catch(error=>{
            console.log(error);
            AppAlert(error.msg,"error")
        })
    }

    const callOnHoldVisitor = (tokenId) => {
        setLoadingStatus(true);
        checkinServices.callOnHoldVisitor(businessId,tokenId).then(res=>{
            setVisitor(res.data);
            socket.emit("send_announcement",{message:"Hello App1","token": res.data})
        }).catch(error=>{

        })
    }

    const updateCheckinStatus  = (status) =>{
        let data = {
            id:visitor.id,
            status:status
        }
        //console.log(data);
        checkinServices.updateCheckinStatus(data).then(res=>{
            setVisitor(null)
            socket.emit("send_close_token",{message:"Token Closed","data": data})
            getPendingList();
            
        }).catch(error=>{
            
        })
    }

    const refreshList = () =>{
        setVisitor(null)
        getPendingList();
    }


    // socket events
    useEffect(()=>{
        //console.log("I called again ");
        socket.on("receive_announcement",(data)=>{
            getPendingList();
            console.log("visitor invited Received",data);
        })
        return () => socket.off('receive_announcement');
    },[socket])
    useEffect(()=>{
        //console.log("I called again ");
        socket.on("receive_new_token",(data)=>{
            getPendingList();
            console.log("new token Received",data);
        })
        return () => socket.off('receive_new_token');
    },[socket])
    useEffect(()=>{
        //console.log("I called again ");
        socket.on("receive_close_token",(data)=>{
            getPendingList();
            console.log("close received",data);
        })
        return () => socket.off('receive_close_token');
    },[socket])


    const [recallButtonStatus,setRecallButtonStatus] = useState(true);
    const recallVisitor = (visitor) =>{

        setRecallButtonStatus(false);

        setTimeout(()=>{
            setRecallButtonStatus(true);
        },15000)

        socket.emit("send_announcement",{message:"Hello App1","token": visitor})
    }
    


    return (
        <AppLayout>
            <div className="container-fluid">
                <div className="row">
                    {notificationList && notificationList.length > 0 &&
                        <div className="col-md-12">
                            <div className="alert alert-info p-2">
                                <marquee direction="left">
                                    {notificationList.map(notification=>
                                        <span key={notification.id}><strong> <Circle/> {notification.title} :- </strong> {notification.details}</span>
                                    )}
                                </marquee>
                            </div>
                        </div>
                    }
                    <div className="col-md-8">
                        <div className="card">
                            <div className="card-header">Visitor</div>
                            <div className="card-body">
                                
                                {!pendingList && !visitor && <h4>No Visitor</h4>} 
                                {pendingList && !visitor && 
                                    <div className="row">
                                        <div className="col-md-12 text-center">
                                            
                                            <button type="button" className="btn btn-primary btn-lg" onClick={callVisitor}><DirectionsWalk/> Call Visitor</button>
                                        </div>

                                        <div className="col-md-12">

                                        {holdTokenExist &&
                                        <div className="card mt-2">
                                            <div className="card-header bg-secondary text-white"> <PanTool/> On Hold</div>
                                            <div className="card-body" style={{maxHeight:300, overflowY:"scroll"}}>
                                                {pendingList &&
                                                    pendingList.map(token=>
                                                        token.status === 7 &&
                                                        <div className="mt-2 p-2 shadow rounded" key={token.id}>
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <span className="float-start"><h4>{token.tokenPrefix}-{token.tokenNo}</h4></span>
                                                                    <span className=""><h4 className={token.status == "1" ? "badge bg-primary" : "badge bg-warning text-danger"}>{token.statusTitle}</h4></span>
                                                                    <button type="button" 
                                                                        className="float-end btn btn-primary"
                                                                        onClick={()=>callOnHoldVisitor(token.id)}
                                                                    >Recall Visitor</button>
                                                                </div>
                                                            </div>
                                                            
                                                            <div>{token.queueTitle} <ArrowRight/> {token.serviceTitle}</div>
                                                            
                                                        </div>
                                                    )
                                                }

                                                {!pendingList &&
                                                    <h4>No Visitor</h4>
                                                }
                                            </div>
                                        </div>
                                        }



                                        </div>
                                    </div>

                                    
                                }
                                
                                {visitor && 
                                    <>
                                        <div className="row mb-2">
                                            <div className="col-6">
                                                <h4> Token # : </h4>
                                            </div>
                                            <div className="col-6">
                                                <span className="bg-primary text-white rounded shadow  p-2" style={{fontSize:26}}>{visitor.tokenPrefix} - {visitor.tokenNo} </span>
                                            </div>
                                        </div>
                                        <LabelValue label="Visitor Name" value={<TokenVisitorDetails visitor={visitor} />} />
                                        
                                        
                                        <div className="row">
                                            <div className="col-6">
                                                <strong> Service : </strong>
                                            </div>
                                            <div className="col-6">
                                                {visitor.queueTitle} <ArrowRight/> {visitor.serviceTitle}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <button type="button" onClick={()=>updateCheckinStatus(4)} className="btn btn-success m-2"><Check/> Finish</button>
                                                <button type="button" disabled={!recallButtonStatus} onClick={()=>recallVisitor(visitor)} className="btn btn-warning m-2"><Campaign/> Recall</button>
                                                <button type="button" onClick={()=>updateCheckinStatus(3)} className="btn btn-danger m-2"><VisibilityOff/> Not Apeared</button>
                                                <button type="button" onClick={()=>updateCheckinStatus(7)} className="btn btn-secondary m-2"><PanTool/> Put on Hold</button>
                                                <span className="float-end mt-5">
                                                    <TransferToken visitor={visitor} refreshList={refreshList} />
                                                </span>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-12">
                                                {/*JSON.stringify(visitor)*/}
                                            </div>
                                        </div>
                                    </>
                                }

                            </div>
                        </div>
                    </div>


                    <div className="col-md-4">
                        <div className="card">
                            <div className="card-header">Next in queue</div>
                            <div className="card-body" style={{height:500, overflowY:"scroll"}}>
                                {pendingList &&
                                    pendingList.map(token=>
                                        token.status !== 7 &&
                                        <div className="mt-2 p-2 shadow rounded" key={token.id}>
                                            <div className="row">
                                                <div className="col-12">
                                                    <span className="float-start"><h4>{token.tokenPrefix}-{token.tokenNo}</h4></span>
                                                    <span className="float-end"><h4 className={token.status == "1" ? "badge bg-primary" : "badge bg-warning text-danger"}>{token.statusTitle}</h4></span>
                                                </div>
                                            </div>
                                            
                                            <div>{token.queueTitle} <ArrowRight/> {token.serviceTitle}</div>
                                            
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </AppLayout>
    )
}

export default withAlert(CheckinQueue)