import React from 'react'
import { Route, Routes } from 'react-router'
import SelectPortalPage from '../auth/pages/SelectPortalPage'
import Login from '../auth/pages/Login'
import Logout from '../auth/pages/Logout'
import Profile from '../auth/pages/Profile'
import Dashboard from '../dashboard/pages/Dashboard'
import UserListPage from '../users/pages/UserListPage'
import UserRolePage from '../users/pages/UserRolePage'

import SystemMasterListPage from '../master/pages/SystemMasterListPage'

import BusinessListPage from '../modules/Business/BusinessListPage'
import BusinessHoursPage from '../app/Queue/pages/BusinessHoursPage'
import BusinessHolidayPage from '../app/Queue/pages/BusinessHolidayPage'
import AppointmentConfigPage from '../app/Queue/pages/AppointmentConfigPage'
import CheckInConfigPage from '../app/Queue/pages/CheckInConfigPage'
import PortalConfigPage from '../app/Queue/pages/PortalConfigPage'
import ServicesPage from '../app/Queue/pages/ServicesPage'
import StaffPage from '../app/Queue/pages/StaffPage'
import QueueListPage from '../app/Queue/pages/QueueListPage'
import BusinessBreaksPage from '../app/Queue/pages/BusinessBreaksPage'
import AppointmentListPage from '../app/Appointments/pages/AppointmentListPage'
import CheckinQueue from '../app/CheckinQueue/pages/CheckinQueue'
import DiasplayPage from '../app/Display/pages/DiasplayPage'

import KioskPortalPage from '../app/Kiosk/pages/KioskPortalPage'
import KioskQueuePage from '../app/Kiosk/pages/KioskQueuePage'

import KioskTokenDetailsPage from '../app/Kiosk/pages/KioskTokenDetailsPage'
import NewsListPage from '../app/News/pages/NewsListPage'
import MiniDisplayPage from '../app/Display/pages/MiniDisplayPage'
import ReportPage from '../app/Report/pages/ReportPage'
import DualMiniDisplayPage from '../app/Display/pages/DualMiniDisplay'
//import KioskCheckinFormPage from '../app/Kiosk/pages/KioskCheckinFormPage'
//import KioskCheckinFormPageWitoutPhone from '../app/Kiosk/pages/KioskCheckinFormPageWitoutPhone'
import KioskCheckinControllerPage from '../app/Kiosk/pages/KioskCheckinControllerPage'
import NotificationListPage from '../app/Notification/pages/NotificationListPage'
import { useSelector } from 'react-redux'



const PageNotFound = () =>{
    return(
        <h3>Lost in space</h3>
    )
}

export default function AppRoutes() {

    const authUser = useSelector((state) => state.auth)

    return (
        <>
            {authUser && authUser.token === undefined &&
                <Routes>
                    <Route path="/" element={ <Login/> } />
                    <Route path="/auth/login" element={ <Login/> } />
                    <Route path="/auth/select-portal" element={ <SelectPortalPage /> } />
                </Routes>
            }
            {authUser && authUser.token !== undefined &&
                <>

                    {authUser.role ==1 &&
                        <Routes>
                            <Route path="/" element={ <Dashboard/> } />
                            <Route path="/auth/profile" element={ <Profile/> } />
                            <Route path="/business/details" element={ <BusinessListPage /> } />
                            <Route path="/user/list" element={ <UserListPage/> } />
                            <Route path="/user/role" element={ <UserRolePage/> } />
                            <Route path="/news/list" element={ <NewsListPage /> } />
                            <Route path="/notification/list" element={ <NotificationListPage /> } />
                            <Route path="/master/list" element={ <SystemMasterListPage /> } />

                            { /* Queue and Queue Config Work */ }
                            <Route path="/queue/list" element={ <QueueListPage />  } />
                            <Route path="/queue/business-hours/:id" element={ <BusinessHoursPage /> } />
                            <Route path="/queue/business-breaks/:id" element={ <BusinessBreaksPage /> } />
                            <Route path="/queue/business-holiday/:id" element={ <BusinessHolidayPage /> } />
                            <Route path="/queue/appointment-config/:id" element={ <AppointmentConfigPage /> } />
                            <Route path="/queue/checkin-config/:id" element={ <CheckInConfigPage /> } />
                            <Route path="/queue/portal-config/:id" element={ <PortalConfigPage /> } />
                            <Route path="/queue/service-config/:id" element={ <ServicesPage /> } />
                            <Route path="/queue/staff-config/:id" element={ <StaffPage /> } />
                            <Route path="/report" element={ <ReportPage/>  } />

                            { /* Kiosk Work Start */ }
                            <Route path="/kiosk/portal/:businessId" element={ <KioskPortalPage/>  } />
                            <Route path="/kiosk/queue/:queueId" element={ <KioskQueuePage/>  } />
                            {/* <Route path="/kiosk/checkin/:businessId/:queueId/:serviceId" element={ <KioskCheckinFormPage/> } /> */}
                            <Route path="/kiosk/checkin/:businessId/:queueId/:serviceId" element={ <KioskCheckinControllerPage/> } />
                            <Route path="/kiosk/token-details" element={ <KioskTokenDetailsPage/> } />
                            { /* Kiosk Work End*/ } 

                            { /* Queue List Work Start */ }
                            <Route path="/checkin/queue" element={ <CheckinQueue />  } />
                            <Route path="/checkin/display" element={ <DiasplayPage/>  } />
                            <Route path="/checkin/mini-display" element={ <MiniDisplayPage/>  } />
                            <Route path="/checkin/dual-mini-display" element={ <DualMiniDisplayPage />  } />
                            <Route path="/appointment/queue" element={ <AppointmentListPage/>  } />
                            { /* Queue List Work End*/ }
                        </Routes>
                    }

                    {authUser.role == 2 &&
                        <Routes>
                            <Route path="/" element={ <Dashboard/> } />
                            <Route path="/auth/profile" element={ <Profile/> } />
                            
                            <Route path="/user/list" element={ <UserListPage/> } />
                            <Route path="/news/list" element={ <NewsListPage /> } />
                            <Route path="/notification/list" element={ <NotificationListPage /> } />

                            { /* Queue and Queue Config Work */ }
                            <Route path="/queue/list" element={ <QueueListPage />  } />
                            <Route path="/queue/business-hours/:id" element={ <BusinessHoursPage /> } />
                            <Route path="/queue/business-breaks/:id" element={ <BusinessBreaksPage /> } />
                            <Route path="/queue/business-holiday/:id" element={ <BusinessHolidayPage /> } />
                            <Route path="/queue/appointment-config/:id" element={ <AppointmentConfigPage /> } />
                            <Route path="/queue/checkin-config/:id" element={ <CheckInConfigPage /> } />
                            <Route path="/queue/portal-config/:id" element={ <PortalConfigPage /> } />
                            <Route path="/queue/service-config/:id" element={ <ServicesPage /> } />
                            <Route path="/queue/staff-config/:id" element={ <StaffPage /> } />
                            <Route path="/report" element={ <ReportPage/>  } />

                            { /* Kiosk Work Start */ }
                            <Route path="/kiosk/portal/:businessId" element={ <KioskPortalPage/>  } />
                            <Route path="/kiosk/queue/:queueId" element={ <KioskQueuePage/>  } />
                            {/* <Route path="/kiosk/checkin/:businessId/:queueId/:serviceId" element={ <KioskCheckinFormPage/> } /> */}
                            <Route path="/kiosk/checkin/:businessId/:queueId/:serviceId" element={ <KioskCheckinControllerPage/> } />
                            <Route path="/kiosk/token-details" element={ <KioskTokenDetailsPage/> } />
                            { /* Kiosk Work End*/ } 

                            { /* Queue List Work Start */ }
                            <Route path="/checkin/queue" element={ <CheckinQueue />  } />
                            <Route path="/checkin/display" element={ <DiasplayPage/>  } />
                            <Route path="/checkin/mini-display" element={ <MiniDisplayPage/>  } />
                            <Route path="/checkin/dual-mini-display" element={ <DualMiniDisplayPage />  } />
                            <Route path="/appointment/queue" element={ <AppointmentListPage/>  } />
                            { /* Queue List Work End*/ }
                        </Routes>
                    }


                    {authUser.role == 3 &&
                        <Routes>
                            { /* Queue Operator Work Start */ }
                            <Route path="/" element={ <Dashboard/> } />
                            <Route path="/checkin/queue" element={ <CheckinQueue />  } />
                            { /* Queue Operator Work End */ }
                        </Routes>
                    }

                    {authUser.role == 4 &&
                        <Routes>
                            { /* Display User Work Start */ }
                            <Route path="/" element={ <Dashboard/> } />
                            <Route path="/checkin/display" element={ <DiasplayPage/>  } />
                            <Route path="/checkin/mini-display" element={ <MiniDisplayPage/>  } />
                            <Route path="/checkin/dual-mini-display" element={ <DualMiniDisplayPage />  } />
                            { /* Display user Work End*/ }
                        </Routes>
                    }

                    {authUser.role == 5 &&
                        <Routes>
                            { /* Kiosk Work Start */ }
                            <Route path="/" element={ <Dashboard/> } />
                            <Route path="/kiosk/portal/:businessId" element={ <KioskPortalPage/>  } />
                            <Route path="/kiosk/queue/:queueId" element={ <KioskQueuePage/>  } />
                            {/* <Route path="/kiosk/checkin/:businessId/:queueId/:serviceId" element={ <KioskCheckinFormPage/> } /> */}
                            <Route path="/kiosk/checkin/:businessId/:queueId/:serviceId" element={ <KioskCheckinControllerPage/> } />
                            <Route path="/kiosk/token-details" element={ <KioskTokenDetailsPage/> } />
                            { /* Kiosk Work End*/ } 
                        </Routes>
                    }

                    <Routes>
                        <Route path="/auth/logout" element={ <Logout/> } />
                        
                    </Routes>
                </>
            }
        </>
    )
}
