import { ArrowRight } from '@mui/icons-material';
import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import UserListPage from '../../../users/pages/UserListPage';
import userServices from '../../../users/userServices';
import newsServices from '../../News/newsServices';
import BottomAdsComponent from '../components/BottomAdsComponent';
import RightAdsComponent from '../components/RightAdsComponent';
import displayServices from '../displayServices';

export default function MiniDisplayPage() {

    const businessId = 1;
    const [displayList,setDisplayList] = useState(null);

    const [portalDetails,setPortalDetails] = useState(null);

    
    const getBusinessDetails = useCallback(()=>{
        displayServices.getPortalDetails(businessId).then(res=>{
            setPortalDetails(res.data.businessDetails)
        }).catch(error=>{
        })
    },[businessId]);
    useEffect(()=>{
        getBusinessDetails()
    },[]);

    
    const getDisplayList = useCallback(() => {
        displayServices.getCheckinDisplayList(businessId).then(res=>{
            setDisplayList(null);
            if(res.data.length > 0 ){
                setDisplayList(res.data)
            }
        })
      },[businessId],
    )

    /* Prepair Master DD*/

    const [masters,setMasters] = useState(null);
    const getUserDD = () => {
        userServices.getUserDD().then(res=>{
            setMasters({"users":res.data})

            getDisplayList();
        })
    }

    const getMasterLabel = (type, id) => {
        let label = masters[type].find(m => m.value === id)
        return (
            <p>{label.workLocation} <ArrowRight/> {label.firstName+" "+label.lastName}</p>
        )
    }

    const [bottomNewsList,setBottomNewList] = useState(null);
    const [rightNewsList,setRightNewList] = useState(null);

    const getNewsList = () =>{
        displayServices.getNewsList().then(res=>{
            res.bottomAds.length > 0 ? setBottomNewList(res.bottomAds) : setBottomNewList(null);
            res.rightAds.length > 0 ? setRightNewList(res.rightAds) : setRightNewList(null);
        }).catch(error=>{
        })
    }


    useEffect(()=>{
        getUserDD();
        const timer = setInterval(()=>{
            if (!document.hidden) {
                getDisplayList();
            } 
        },10000)
        return ()=> clearInterval(timer)
    },[])

    useEffect(()=>{
        //getNewsList();
    },[])

    const [selectedCounter,setSelectedCounter] = useState(null);

  return (
    <div className="container-fluid">
        {/*Header End  */}
        <div className="row shadow p-2 mb-3">
            <div className="col-12 text-center">
                {portalDetails && 
                    <Link className="navbar-brand" to="/auth/logout">
                        {portalDetails.logo ? <img src={portalDetails.logo} style={{maxHeight:70}} alt={portalDetails.title} /> : portalDetails.title} 
                    </Link>
                }
            </div>
        </div>
        {/*Header End  */}

        {selectedCounter === null && 
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header bg-primary text-white">Select Counter</div>
                        <div className="card-body">
                        <div class="list-group">
                            {masters && masters['users'].map(user=>
                                <a href="javascript:void(0)" onClick={()=>setSelectedCounter(user)} class="list-group-item list-group-item-action">
                                <span className="text-primary">{user.firstName} {user.lastName} </span> <span className="bg-success p-2 text-white rounded">{user.workLocation}</span>
                                </a>
                            )}
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        }

        {selectedCounter && 
            <div className="row">
                <div className={rightNewsList !== null ? "col-md-9" : "col-md-12"}>
                    <div className="row">
                        <div className="col-12 text-center fw-bold bg-primary p-2 text-white">
                            <span style={{fontSize:40}}>{selectedCounter.workLocation}</span>
                        </div>
                        <div className="col-12 text-center fw-bold bg-danger p-2 text-white">
                            <span style={{fontSize:40}}>{selectedCounter.firstName}</span>
                        </div>
                        <div className="col-12">
                                    {displayList && 
                                        displayList.map(token =>
                                            token.status === 2 && token.counterUserId == selectedCounter.value &&
                                                <div className="card">
                                                    
                                                    <div className="card-body">
                                                        <div className="row mt-3">
                                                            <div className="col-12 mb-3 text-center">
                                                                <span className="bg-success text-white p-3 rounded shadow" style={{fontSize:60}}>
                                                                    {token.tokenPrefix}-{token.tokenNo}
                                                                </span>
                                                            </div>
                                                            
                                                            {token.firstName !== "" && 
                                                            <div className="col-12 text-center" >
                                                                <span className="fw-bold" style={{fontSize:50}}>{token.firstName}-{token.lastName}</span>
                                                            </div>
                                                            }

                                                        </div>
                                                    </div>
                                                </div>

                                        )
                                    }
                                
                        </div>
                    </div>
                </div>
                {rightNewsList &&
                    <div className="col-md-3">
                        { /* <RightAdsComponent newsList={rightNewsList} />  */ }
                    </div>
                }
                
            </div>
        }
        

        { /* bottomNewsList && <BottomAdsComponent newsList={bottomNewsList} portalTitle={portalDetails.title} />  */}
        
    </div>
  )
}
