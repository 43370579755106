import { CircularProgress } from '@mui/material'
import React,{useState,useEffect} from 'react'
import { useParams } from 'react-router';
import withAlert from '../../../hoc/withAlert';

import { KeyboardArrowRight } from '@mui/icons-material'
import kioskServices from '../kioskServices';

import KioskCheckinFormPage from './KioskCheckinFormPage'
import KioskCheckinFormPageWitoutPhone from './KioskCheckinFormPageWitoutPhone'


function KioskCheckinControllerPage({AppAlert}) {

    let { queueId } = useParams();
    const [queueDetails,setQueueDetails] = useState(null);

    const getQueueDetails = () =>{
        kioskServices.getPortalQueueDetails(queueId).then(res=>{
            setQueueDetails(res.data.queueDetails);
        }).catch(error=>{
            AppAlert(error.msg,"error")
        })
    }

    useEffect(()=>{
        getQueueDetails();
    },[queueId])

    return (
        <>
            { queueDetails == null ?
                <div className="mt-5 text-center"><CircularProgress/></div>
                :
                <KioskCheckinFormPage queueDetails={queueDetails} />
            }
        </>
    )
}

export default withAlert(KioskCheckinControllerPage)


{/*
    { queueDetails == null ?
        <div className="mt-5 text-center"><CircularProgress/></div>
        :
        queueDetails.otpPhone == 1 ?
        <KioskCheckinFormPage queueDetails={queueDetails} />
        :
        <KioskCheckinFormPage queueDetails={queueDetails} />
    }
*/}