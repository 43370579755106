import React, { useCallback, useContext, useEffect, useState } from 'react'
import { LinearProgress, Slide, StepContent } from '@mui/material';
import { useNavigate, useParams } from 'react-router';

import { Step, StepLabel, Stepper } from '@mui/material';
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../components/FormikControl/FormikControl';
import OTPInput, { ResendOTP } from "otp-input-react";


import withAlert from '../../../hoc/withAlert';
import AppLayout from '../../../layout/components/AppLayout';
import kioskServices from '../kioskServices';
import { SocketContext } from '../../../context/socket';
import CustomerImage from '../components/CustomerImage';

function KioskCheckinFormPage({queueDetails, AppAlert}) {
    let navigate = useNavigate();
    let {businessId, queueId,serviceId } = useParams();
    const [formValues, setFormValues] = useState(null);
    const [savingInProgress, setSavingStatus] = useState(false);

    const [profileImage,setProfileImage] = useState(null);


    const initialValues = {
        firstName:"",
        businessId:businessId,
        queueId:queueId,
        serviceId:serviceId
    }

    const socket = useContext(SocketContext);

    
    const onSubmitTesting = (values, onSubmitProps) => {

        if(profileImage !== null ){
            values.profileImage = profileImage;
        }
        console.log("ready to submit -->");
        console.log(values);
        console.log("<-- aready to submit");
    }

    const onSubmit = (values, onSubmitProps) => {
        setSavingStatus(true);
        if(profileImage !== null ){
            values.profileImage = profileImage;
        }
        kioskServices.kioskCheckin(values).then(res=>{
            socket.emit("send_new_token",{message:"new token received"})
            AppAlert(res.msg);
            localStorage.setItem('customerToken',JSON.stringify(res.customerToken));
            navigate("/kiosk/token-details");
        }).catch(error=>{
            AppAlert(error.msg,"error");
        }).finally(()=>{
            setSavingStatus(false);
        })
    }

    const validationRule = Yup.object({});

    const [tokenValidationRule, setTokenValidationRule] = useState(null);

    const prepareValidationRue = () =>{
        let rules = {};
        console.log(queueDetails);
        
        if(queueDetails.otpEmail == 1){
            rules.email=Yup.string().email().required("Required");
        }

        if(queueDetails.otpPhone == 1){
            rules.phone=Yup.string().required("Required").matches(/^[0-9]+$/, "Must be only digits").min(10, 'Invalid Phone Number').max(10, 'Invalid Phone Number');
        }

        if(queueDetails.addressRequired == 1){
            rules.address=Yup.string().required("Required");
        }

        if(queueDetails.ageRequired == 1){
            rules.age=Yup.string().required("Required");
        }

        if(queueDetails.idProofRequired == 1){
            rules.idType=Yup.string().required("Required");
            rules.idProof=Yup.string().required("Required");
        }

        if(queueDetails.remarkRequired == 1){
            //rules.remark=Yup.string().required("Required");
        }

        
        
        let _tokenValidationRule = Yup.object(rules)
        setTokenValidationRule(_tokenValidationRule)
    }

    const prepareFormValues = () =>{
        let _formValues = {};
            _formValues.firstName="";
            _formValues.businessId=businessId;
            _formValues.queueId=queueId;
            _formValues.serviceId=serviceId;

        if(queueDetails.otpPhone == 1){
            _formValues.phone = "";
        }

        setFormValues(_formValues);
    }

    useEffect(()=>{
        //prepareFormValues();
        prepareValidationRue();
    },[])

    const idTypeList = [
        {label:"NA", value:"0"},
        {label:"driving L", value:"1"},
        {label:"Aadhar card", value:"2"},
        {label:"Pan card", value:"3"},
        {label:"Employee Id", value:"4"},
        {label:"Voter Id", value:"5"},
        {label:"Passport Id", value:"6"},
    ]

    return (
        <AppLayout>
        {queueDetails && 
            <div className="container">
                <Slide direction="left" in={true} mountOnEnter unmountOnExit>
                    <div className="">
                        <div className="p-2">
                            <Formik
                                initialValues={formValues || initialValues}
                                validationSchema={tokenValidationRule || validationRule}
                                onSubmit={onSubmit}
                                enableReinitialize validateOnMount
                            >
                                {
                                    (formik) => {
                                        //console.log(formik.values);
                                        return (
                                            <Form>
                                                    
                                                        <div className="row p-2">
                                                            <div className="col-md-12"> 
                                                                                    {queueDetails.photoRequired == 1 && 
                                                                                    <div className="row mt-4">
                                                                                        <div className="col-md-12 text-center">
                                                                                            <CustomerImage setProfileImage={setProfileImage}/>
                                                                                        </div>
                                                                                    </div>
                                                                                    }
                                                                                    <div className="row mt-4">
                                                                                        <div className="text-center">
                                                                                            
                                                                                            <FormikControl control="input" formik={formik} type="text" 
                                                                                                label="Your Name" name="firstName"
                                                                                                className="col-md-12"
                                                                                            />

                                                                                            {queueDetails.otpEmail == 1 && 
                                                                                            <FormikControl control="input" formik={formik} type="email" 
                                                                                                label="Email*" name="email" 
                                                                                                className="col-md-12"
                                                                                            />
                                                                                            }

                                                                                            {queueDetails.otpPhone == 1 && 
                                                                                            <FormikControl control="input" formik={formik} type="text" 
                                                                                                label="Mobile Number*" name="phone" 
                                                                                                className="col-md-12"
                                                                                            />
                                                                                            }


                                                                                            {queueDetails.ageRequired == 1 && 
                                                                                            <FormikControl control="input" formik={formik} type="number" 
                                                                                                label="Age*" name="age"
                                                                                                className="col-md-12 "
                                                                                            />
                                                                                            }

                                                                                            {queueDetails.idProofRequired == 1 && 
                                                                                            
                                                                                                <div className="col-md-12">
                                                                                                    <div className="row mt-4">
                                                                                                        <div className="col-md-6">
                                                                                                            <FormikControl control="autoselect" formik={formik} 
                                                                                                                label="ID Type" name="idType"  
                                                                                                                options={idTypeList} multiple={false} 
                                                                                                            />
                                                                                                        </div>

                                                                                                        <FormikControl control="input" formik={formik} type="text" 
                                                                                                            label="Id Proff Details" name="idProof"
                                                                                                            className="col-md-6"
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                            }

                                                                                            {queueDetails.addressRequired == 1 && 
                                                                                            <FormikControl control="input" formik={formik} type="text" 
                                                                                                label="Address" name="address"
                                                                                                className="col-md-12"
                                                                                            />
                                                                                            }

                                                                                            {queueDetails.remarkRequired == 1 && 
                                                                                            <FormikControl control="input" formik={formik} type="text" 
                                                                                                label="Remark" name="remark"
                                                                                                className="col-md-12"
                                                                                            />
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                        { /*  JSON.stringify(formik.values)  */ }
                                                        </div>

                                                        <div className="row">
                                                        { /*  formik.isValid ? "true" :"false" */ }
                                                        </div>
                
                                                    {!savingInProgress && 
                                                        <>
                                                            <div className="col-md-12">
                                                                <button className="btn btn-success float-end" type="submit">Check In </button>
                                                            </div>
                                                        </>
                                                    }

                                                    {savingInProgress && <LinearProgress/>}

                                                    
                                                
                                            </Form>
                                        )
                                    }
                                }
                            </Formik>
                        </div>
                    </div>
                </Slide>
            </div>
        }
        </AppLayout>
    )
}

export default withAlert(KioskCheckinFormPage)