import { ArrowRight } from '@mui/icons-material';
import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import UserListPage from '../../../users/pages/UserListPage';
import userServices from '../../../users/userServices';
import newsServices from '../../News/newsServices';
import BottomAdsComponent from '../components/BottomAdsComponent';
import MiniDisplayComponent from '../components/MiniDisplayComponent';
import RightAdsComponent from '../components/RightAdsComponent';
import displayServices from '../displayServices';
import MiniDisplayPage from './MiniDisplayPage';

export default function DualMiniDisplayPage() {


    const businessId = 1;
    const [portalDetails,setPortalDetails] = useState(null);
    const getBusinessDetails = useCallback(()=>{
        displayServices.getPortalDetails(businessId).then(res=>{
            setPortalDetails(res.data.businessDetails)
        }).catch(error=>{
        })
    },[businessId]);

    const [bottomNewsList,setBottomNewList] = useState(null);
    const [rightNewsList,setRightNewList] = useState(null);

    const getNewsList = () =>{
        displayServices.getNewsList().then(res=>{
            res.bottomAds.length > 0 ? setBottomNewList(res.bottomAds) : setBottomNewList(null);
            res.rightAds.length > 0 ? setRightNewList(res.rightAds) : setRightNewList(null);
        }).catch(error=>{
        })
    }

    useEffect(()=>{
        getBusinessDetails()
        getNewsList();
    },[]);

    

  return (
    <div className="container-fluid">
        {/*Header End  */}
        <div className="row shadow p-2 mb-3">
            <div className="col-12 text-center">
                {portalDetails && 
                    <Link className="navbar-brand" to="/auth/logout">
                        {portalDetails.logo ? <img src={portalDetails.logo} style={{maxHeight:70}} alt={portalDetails.title} /> : portalDetails.title} 
                    </Link>
                }
            </div>
        </div>
        {/*Header End  */}
        <div className="row">
            <div className="col-md-4">
                <MiniDisplayComponent />
            </div>

            <div className="col-md-4">
                {rightNewsList &&
                     <RightAdsComponent newsList={rightNewsList} />  
                }
            </div>

            <div className="col-md-4">
                <MiniDisplayComponent />
            </div>
        </div>
        <div className="row">
            
            <div className="col-md-12">
            {  bottomNewsList && <BottomAdsComponent newsList={bottomNewsList} portalTitle={portalDetails.title} />  }
            </div>
        </div>
    </div>
    
  )
}
