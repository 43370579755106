import React from 'react'

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

function RightAdsComponent({newsList}) {
  return (
        <div className="row">
            <Carousel axis="horizontal" 
             interval={5000}
             dynamicHeight={false}
             showThumbs={false} showStatus={false} showIndicators={false} autoPlay={true} infiniteLoop={true} >

                {newsList.map(news=>
                    <div key={news.id}>
                        <img src={news.image} style={{minHeight:70}} alt={news.title} />
                        {news.details != "" &&
                            <>
                            <p className="fw-bold mt-3">{news.title}</p>
                            <p>{news.details}</p>
                            </>
                        }
                        
                    </div>
                )}
            </Carousel>
        </div>
  )
}

export default RightAdsComponent