import React, { useEffect, useState } from 'react'
import ConfirmDialog from '../../../components/ConfirmDialog';
import InlineImage from '../../../components/InlineEdit/InlineImage';
import withAlert from '../../../hoc/withAlert'
import useSystemMasterList from '../../../hooks/useSystemMasterList';
import AppLayout from '../../../layout/components/AppLayout'
import NewsForm from '../components/NewsForm';
import newsServices from '../newsServices';
import AwsImageInline from '../../../components/InlineEdit/AwsImageInline';
import { CircularProgress, Dialog, DialogContent, LinearProgress } from '@mui/material';


const NewsListPage = ({AppAlert}) => {

    const [newsList,setNewsList] = useState(null);
    
    const getNewsList = () =>{
        newsServices.get().then((res)=>{        
            setNewsList(res.data);
        }).catch(error=>{
        })
    }

    const [masters,getMasterLabel] = useSystemMasterList(["news-type"]);

    useEffect(()=>{
        getNewsList();
    },[]);


    const remove = (id) => {
        newsServices.remove(id).then(res=>{
            AppAlert(res.msg)
            getNewsList();
        }).catch(error=>{
            AppAlert(error.msg);
        })

    }

    const [dialogStatus, setDialogStatus] = useState(false);
    const handleClose = () =>{
        setDialogStatus(false)
        setOtpId(null)
    }
    const [otpId, setOtpId] = useState(null);
    const [otp,setOtp] =  useState("");
    const [otpSent,setOtpSent] =  useState(false);
    const [loading,setLoadingStatus] = useState(false);

    
    const showOtpDialog = (id) =>{
        setOtp("");
        setOtpId(id);
        setDialogStatus(true);
        setOtpSent(false);
    }

    const requestOTP = (id) => {
        setLoadingStatus(true);
        newsServices.requestOtp(id).then(res=>{
            AppAlert("Otp Sent","success")
            setOtpSent(true);
        }).catch(err=>{
            AppAlert("somthing went Wrong","error")
        }).finally(()=>{
            setLoadingStatus(false);
        })
    }

    const verifyOtp = () => {
        setDialogStatus(true);
        newsServices.verifyOtp({id:otpId,otp:otp}).then(res=>{
            AppAlert("Published")
            getNewsList();
            setDialogStatus(false);
            setOtpId(null)
        }).catch(err=>{
            AppAlert(err.msg,"error")
        })
    }

    return (
        <AppLayout>
            <div className="container-fluid mt-4">
                <div className="row">
                    <div className="col-12" >
                      <span className="float-end">{masters && <NewsForm masters={masters} AppAlert={AppAlert} refreshList={getNewsList} /> }</span> 
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-md-12">
                        { masters &&
                            <div className="row">
                                <div className="col-md-12">
                                    <h3>Right Side Ads</h3>
                                </div>
                                {newsList &&
                                    newsList.map(news=>{
                                        if(news.newsType===1){
                                            return (
                                            <div key={news.id} className="col-md-4">
                                                <div className="card">
                                                    <div className="card-header">
                                                        {news.title}
                                                        <span className="float-end">
                                                            {masters && <NewsForm data={news} masters={masters} AppAlert={AppAlert} refreshList={getNewsList} /> }
                                                            <ConfirmDialog id={news.id} confirm={remove} >Are you sure you want to remove this ad</ConfirmDialog>
                                                        </span> 
                                                        </div>
                                                    <div className="card-body">

                                                    {/* <InlineImage editId={news.id} name="image" value={news.image} handleInlineChange={newsServices.updateInline}  /> */}

                                                        <AwsImageInline
                                                            label="News Image"
                                                            name="image"
                                                            value={news.image}
                                                            editId={news.id}
                                                            updateInline={newsServices.updateInline}
                                                            AppAlert={AppAlert}
                                                            module="news"
                                                            fileId={news.id}
                                                            onSuccess={getNewsList}
                                                        />

                                                    </div>
                                                    <div className="card-footer">
                                                        {news.published === 1 ? 
                                                            <button type="button" className="btn btn-success" disabled={true} >Published</button>
                                                         : 
                                                            <button type="button" className="btn btn-success" onClick={()=>showOtpDialog(news.id)} >Published Now</button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            )
                                        }
                                        
                                    }
                                        
                                    )
                                }

                                <div className="col-md-12 pt-2">
                                    <hr />
                                    <h3>Bottom Ads</h3>
                                </div>
                                {newsList &&
                                    newsList.map(news=>{
                                        if(news.newsType===2){
                                            return (
                                            <div key={news.id} className="col-md-4">
                                                <div className="card">
                                                    <div className="card-header">
                                                        {news.title}
                                                        <span className="float-end">
                                                            {masters && <NewsForm data={news} masters={masters} AppAlert={AppAlert} refreshList={getNewsList} /> }
                                                            <ConfirmDialog id={news.id} confirm={remove} >Are you sure you want to remove this ad</ConfirmDialog>
                                                        </span> 
                                                        </div>
                                                    <div className="card-body">
                                                        <p>{news.details}</p>
                                                    </div>
                                                    <div className="card-footer">
                                                        {news.published === 1 ? 
                                                            <button type="button" className="btn btn-success" disabled={true} >Published</button>
                                                         : 
                                                            <button type="button" className="btn btn-success" onClick={()=>showOtpDialog(news.id)} >Published Now</button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            )
                                        }
                                    }
                                    )
                                }
                                
                            </div>
                        }
                    </div>
                </div>
            </div>

            
            <Dialog
                open={dialogStatus}
                keepMounted
                onClose={handleClose}
                fullWidth={false}
                maxWidth="xs"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent>
                    <div className="row">
                        
                        <div className="col-md-6 offset-md-3 text-center">
                            {otpSent &&
                            <input type="text" className="form-control" placeholder="Enter OTP"
                                onChange={(e)=>setOtp(e.target.value)}
                                value={otp}
                            ></input>
                            }
                        </div>
                        <div className="col-md-12 mt-3 text-center">
                            {!loading ?
                                <>
                                    {!otpSent ? 
                                        <button type="button" className="btn btn-primary" onClick={()=>requestOTP(otpId)}>Request Otp</button>
                                        :
                                        <button type="button" className="btn btn-primary"disabled={otp.length !== 6} onClick={verifyOtp}>Verify OTP</button>
                                    }
                                </>
                                :
                                <CircularProgress />
                            }
                            
                            <button type="button" className="btn btn-outline-primary ms-2" onClick={handleClose}> Cancel</button>
                        </div>
                    </div>
                </DialogContent>

            </Dialog>
        </AppLayout>
    )
}

export default withAlert(NewsListPage);