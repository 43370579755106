import React,{useState} from 'react'
import checkinServices from '../checkinServices';

function TokenVisitorDetails({visitor}) {
    const [dialogStatus, setDialogStatus] = useState(false);

    const [visitorDetails,setVisitorDetails] = useState(null)

    const getVisitorDetails = () => {
        checkinServices.getVisitorDetails(visitor.customerId).then(res=>{
            setVisitorDetails(res.data);
        })
    }

    return (
        <>
            {!visitorDetails ?
                <button type="button" className="btn btn-outline-primary" onClick={getVisitorDetails} >{visitor.firstName===""?"--":visitor.firstName}</button>
                :
                <div className="row">
                    {visitorDetails.profileImage !== null &&
                        <div className="col-12">
                            <img src={visitorDetails.profileImage} class="img-thumbnail" alt="Visitor Photo" />
                        </div>
                    }
                    <div className="col-4">Name</div>
                    <div className="col-8">{visitorDetails.firstName===""?"--":visitorDetails.firstName}</div>
                    <div className="col-4">Phone</div>
                    <div className="col-8">{(visitorDetails.phone==="" || visitorDetails.phone===null)?"--":visitorDetails.phone}</div>
                </div>
            }
            <hr/>
        </>
    )
}

export default TokenVisitorDetails